import './bootstrap';

// import {Swiper, Navigation, Pagination} from 'swiper';
// import { Autoplay, Navigation, Pagination } from "swiper";
// import Swiper from "swiper";
// Swiper.use([Autoplay, Navigation, Pagination]);

// komplet
// import Swiper from 'swiper/bundle';
// window.Swiper = Swiper;

// pouze vybrane moduly
import {Swiper, Navigation} from 'swiper';
window.Swiper = Swiper.use([Navigation]);
