window._ = require('lodash');

try {

    window.$ = window.jQuery = require('jquery');
    window.Popper = require('@popperjs/core');
    window.bootstrap = require('bootstrap');
    // window.Tree = require('@widgetjs/tree');    // https://github.com/daweilv/treejs
    // window.Alpine = require('alpinejs');    // https://alpinejs.dev/essentials/installation (instalu zvlast, nejak nefunguje, asi problem s defer)

    // NEFUNKCNI - NEVIDI NAZEV SWIPER, NEKDE SE MUSI PREDAVAT (resim v app.js pomoci import() )
    // window.Swiper = require('swiper/js/swiper');
    // let Swiper = require('swiper/bundle');   // nevidi nazev
    // window.Swiper = Swiper;
// import Swiper from 'swiper/bundle';
//     window.Swiper = Swiper;
    // window.Swiper = require('swiper');   // NEFUNKCNI
    // let Swiper = window.Swiper = require('swiper');
    // window.Swiper = require('swiper').default;
    // window.Swiper = Swiper;
    // window.SwiperNavigation = Navigation;
    // window.SwiperPagination = Pagination;

    // window.Swiper = require('swiper').default;
    // const Swiper = require('swiper').default;
    // presunuto o uroven vyse do app.js

} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
